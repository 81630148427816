import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Thumbnail from "./Thumbnail";
import peopleImage2 from "../assets/peopleImage2.jpeg";
import publicationImage2 from "../assets/homeImage.jpeg";
import ContactImage3 from "../assets/contactImage3.jpeg";
import Typography from "@mui/material/Typography";
import News from "./News";
import Container from "@mui/material/Container";
import {useTheme} from "@mui/material/styles";

function NewsSection() {
    const theme = useTheme();
    return (
        <Container maxWidth='xl' sx={{textAlign: 'start'}}>
            <Box sx={{mb: 4}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Thumbnail image={peopleImage2} text={'People'}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Thumbnail image={publicationImage2} text={'Publications'}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Thumbnail image={ContactImage3} text={'Contact'}/>
                    </Grid>
                </Grid>
            </Box>
            <Typography variant={'h4'}>Recent News</Typography>

            <News>
                February 2025: Pawan's work DPWatch: A Framework For Hardware-Based Differential Privacy Guarantees has been accepted at{' '}
                <a
                    href='https://ieeexplore.ieee.org/xpl/RecentIssue.jsp?punumber=10208'
                    style={{textDecoration: 'none', color: theme.palette.info.main}}>
                    IEEE Computer Architecture Letters
                </a>!
            </News>

            <News>
                January 2025: Ruofan's work DiffusionRenderer: Neural Inverse and Forward Rendering with Video Diffusion Models has been accepted at{' '}
                <a
                    href='https://cvpr.thecvf.com/Conferences/2025'
                    style={{textDecoration: 'none', color: theme.palette.info.main}}>
                    CVPR 2025
                </a>!
            </News>

            <News>
                January 2025: Gavin's work Retri3D: 3D Neural Graphics Representation Retrieval has been accepted at{' '}
                <a
                    href='https://iclr.cc/Conferences/2025'
                    style={{textDecoration: 'none', color: theme.palette.info.main}}>
                    ICLR 2025
                </a> as a Spotlight!
            </News>

            <News>
                December 2024: Gavin's work INRet: A General Framework for Accurate Retrieval of INRs for Shapes has been accepted at{' '}
                <a
                    href='https://3dvconf.github.io/2025/'
                    style={{textDecoration: 'none', color: theme.palette.info.main}}>
                    3DV 2025
                </a>!
            </News>

            <News>
                December 2024: Andreas's work DEQuify your force field: Towards efficient simulations using deep equilibrium models has won the best paper award at{' '}
                <a
                    href='https://moleculediscovery.github.io/workshop2024/'
                    style={{textDecoration: 'none', color: theme.palette.info.main}}>
                    ELLIS Machine Learning for Molecules Workshop 2024
                </a>!
            </News>

            <News>
                December 2024: Chunlin, Hanrui, Xiaorui and Ruofan's work DISORF: A Distributed Online 3D Reconstruction Framework for Mobile Robots has been accepted at{' '}
                <a
                    href='https://ieeexplore.ieee.org/xpl/RecentIssue.jsp?punumber=7083369'
                    style={{textDecoration: 'none', color: theme.palette.info.main}}>
                    IEEE Robotics and Automation Letters 2025
                </a>!
            </News>

            <News>
                December 2024: Adrian and Sankeerth's work ARC: Warp-level Adaptive Atomic Reduction in GPUs to Accelerate Differentiable Rendering has been accepted at{' '}
                <a
                    href='https://www.asplos-conference.org/asplos2025/'
                    style={{textDecoration: 'none', color: theme.palette.info.main}}>
                    ASPLOS 2025
                </a>!
            </News>

            {/*<News>*/}
            {/*    October 2024: We are organizing a workshop for women undergraduate students on October 18th, 2024. If you are curious about what a career in computer systems/architecture could look like, this workshop is for you! You can find out more and register{' '}*/}
            {/*    <a*/}
            {/*        href='https://sites.google.com/view/systemssavvy2024/home?authuser=0'*/}
            {/*        style={{textDecoration: 'none', color: theme.palette.info.main}}>here</a>*/}
            {/*    .*/}
            {/*</News>*/}

            {/*<News>*/}
            {/*    September 2024: We are looking for stellar post-doctoral researchers to join our group. Please email{' '}*/}
            {/*    <a*/}
            {/*        href='mailto:nandita@cs.toronto.edu'*/}
            {/*        style={{textDecoration: 'none', color: theme.palette.info.main}}>nandita@cs.toronto.edu</a>{' '}*/}
            {/*    if you are interested.*/}
            {/*</News>*/}

            {/*<News>*/}
            {/*    September 2024: We welcome our new graduate students for 2024: Egil and Mohammadreza!*/}
            {/*</News>*/}

            {/*<News>July 2024: Ruofan's work Photorealistic Object Insertion with Diffusion-Guided Inverse Rendering has been accepted at{' '}*/}
            {/*    <a*/}
            {/*        href='https://eccv.ecva.net/Conferences/2024'*/}
            {/*        style={{textDecoration: 'none', color: theme.palette.info.main}}>*/}
            {/*        ECCV 2024*/}
            {/*    </a>!*/}
            {/*</News>*/}

            {/*<News>June 2024: Sankeerth's work ACE: Efficient GPU Kernel Concurrency for Input-Dependent Irregular Computational Graphs has been accepted at{' '}*/}
            {/*    <a*/}
            {/*        href='https://pact2024.github.io/'*/}
            {/*        style={{textDecoration: 'none', color: theme.palette.info.main}}>*/}
            {/*        PACT 2024*/}
            {/*    </a>!*/}
            {/*</News>*/}

            {/*<News>May 2024: Sankeerth presented Distributed Training of Neural Radiance Fields: A Performance Characterization at{' '}*/}
            {/*    <a*/}
            {/*        href='https://www.ispass.org/ispass2024/'*/}
            {/*        style={{textDecoration: 'none', color: theme.palette.info.main}}>*/}
            {/*        ISPASS 2024*/}
            {/*    </a>!*/}
            {/*</News>*/}

            {/*<News>*/}
            {/*    May 2024:{' '}*/}
            {/*    <a*/}
            {/*        href='https://arxiv.org/abs/2404.12512'*/}
            {/*        style={{textDecoration: 'none', color: theme.palette.info.main}}>*/}
            {/*        Proteus: Preserving Model Confidentiality during Graph Optimizations*/}
            {/*    </a>*/}
            {/*    {' '}by Yubo Gao and Maryam Haghifam has been accepted at{' '}*/}
            {/*    <a*/}
            {/*        href='https://mlsys.org/Conferences/2024'*/}
            {/*        style={{textDecoration: 'none', color: theme.palette.info.main}}>*/}
            {/*        MLSys 2024*/}
            {/*    </a>*/}
            {/*    . The paper will be presented at{' '}*/}
            {/*    <a*/}
            {/*        href='https://mlsys.org/Conferences/2024'*/}
            {/*        style={{textDecoration: 'none', color: theme.palette.info.main}}>*/}
            {/*        MLSys 2024*/}
            {/*    </a>*/}
            {/*    {' '}(May 2024).*/}
            {/*</News>*/}

        </Container>
    );
}

export default NewsSection;