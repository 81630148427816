import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import footerLogo from '../assets/embarc_footer.png'

function Footer() {
    return (
        <Box sx={{
            height: 'fit-content',
            backgroundColor: '#eeeeee',
            width: '100%',
            marginTop: '100px'
        }}
             style={{
                 bottom: 0
             }}>
            <Container
                sx={{
                    py: 1,
                    justifyContent: 'start',
                    display: 'flex',
                    // backgroundColor: '#eeeeee'
                }}
                maxWidth="xl"
            >
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <img src={footerLogo} style={{width: '10rem'}} alt={"embArc"}></img>
                    <Typography variant='h5'>
                        2025
                    </Typography>
                </Box>
            </Container>
        </Box>

    );
}

export default Footer;
