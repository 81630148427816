import Box from "@mui/material/Box";
import AccelerationConsortium from "../assets/logo/AccelerationConsortium.jpg";
import AMD from "../assets/logo/AMD.jpg";
import AWS from "../assets/logo/AWS.jpg";
import CENTML from "../assets/logo/CENTML.jpg";
import CSE from "../assets/logo/CSE.jpg";
import intel from "../assets/logo/intel.jpg";
import LG from "../assets/logo/LG.jpg";
import mitacs from "../assets/logo/mitacs.jpg";
import NSERC from "../assets/logo/NSERC.jpg";
import NVIDIA from "../assets/logo/NVIDIA.jpg";
import Sapeon from "../assets/logo/Sapeon.jpg";
import SONY from "../assets/logo/SONY.jpg";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

function LogoGrid() {
    const images = [AccelerationConsortium, AMD, AWS, CENTML, CSE, intel, LG, mitacs, NSERC, NVIDIA, Sapeon, SONY];

    return (
        <Box sx={{ flexGrow: 1, padding: 2 }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 7 }}>
                {images.map((image, index) => (
                    <Box
                        key={index}
                        component="img"
                        sx={{
                            width: "auto",
                            height: 30,
                        }}
                        src={image}
                        alt={`Image ${index + 1}`}
                    />
                ))}
            </Box>
        </Box>
    );
}

function CollabSection() {
    return (
        <Container maxWidth='xl' sx={{textAlign: 'start', marginTop: '90px'}}>
            <Typography variant={'h4'}>Collaborators and Funding Partners</Typography>

            <br/>

            <LogoGrid/>

        </Container>
    );
}

export default CollabSection;